// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Flex, Text, View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import SophiaPromoTermsAndConditionsDialogTrigger from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsDialogTrigger';
import SophiaPromoTermsAndConditionsHoverOverTooltip from 'common/components/sophia/sophia-promo/sophia-promo-terms-and-conditions/SophiaPromoTermsAndConditionsHoverOverTooltip';

import SophiaPromoActions from './sophia-promo-actions/SophiaPromoActions';
import SophiaPromoCloseButton from './sophia-promo-close-button/SophiaPromoCloseButton';
import SophiaPromoDescription from './sophia-promo-description/SophiaPromoDescription';
import SophiaPromoGradient from './sophia-promo-gradient/SophiaPromoGradient';
import SophiaPromoIcon from './sophia-promo-icon/SophiaPromoIcon';
import SophiaPromoBasePrice from './sophia-promo-prices/SophiaPromoBasePrice';
import SophiaPromoDiscountedPrice from './sophia-promo-prices/SophiaPromoDiscountedPrice';
import SophiaPromoTag from './sophia-promo-tag/SophiaPromoTag';
import SophiaPromoTitle from './sophia-promo-title/SophiaPromoTitle';

/**
 * SophiaPromoRightRailPanel component. This is a panel that is used in the right rail of the Sophia promo,
 * one of a set of related panels that can contain different combinations of the child components, depending
 * on the type of panel. For example, the Banner version does not have room to display a hoverbox for legal
 * terms and conditions, so the valid children for that component will not include that child component (the
 * SophiaPromoHoverOverTooltip).
 *
 * The component is designed to be used with the JsonPayloadProvider, which will provide the JSON inputPayload
 * data to the children of the component, and allow for optional transforms to be applied to the inputPayload
 * data before it is passed to the children. The transforms are not exposed to the children, and should not be
 * manipulated by the children.
 *
 * @returns {Element} the SophiaPromoRightRailPanel component
 * @constructor SophiaPromoRightRailPanel component
 */
const SophiaPromoRightRailPanel = ({onDismiss, onCardCTAAction}) => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  if (!payload) {
    return null;
  }

  const showLink = !!payload.termsAndConditions && !!payload.termsHeading && !!payload.termsLabel;

  const showPriceFrequency = !!payload.priceFrequency;
  const displayExcludingVAT = !!payload.displayExcludingVAT;

  const priceFrequencyLabel = displayExcludingVAT
    ? intl.formatMessage(
        {id: 'common.sophia.sophiaPromo.pricing.perLicenseExcludingVAT.label'},
        tokens
      )
    : intl.formatMessage({id: 'common.sophia.sophiaPromo.pricing.perLicense.label'}, tokens);

  return (
    <View
      borderColor="gray-300"
      borderRadius="regular"
      borderWidth="thin"
      data-testid="one-console-promo-side"
      padding="size-400"
      position="relative"
      UNSAFE_style={{
        backgroundColor: payload.backgroundColor,
        boxShadow: '0 0.1875rem 0.375rem rgba(0,0,0,.08)',
      }}
    >
      <SophiaPromoGradient />
      {onDismiss && <SophiaPromoCloseButton onDismiss={onDismiss} />}

      <View data-testid="one-console-promo-side-header" position="relative">
        <SophiaPromoIcon />
        <SophiaPromoTag mode="right-rail" />
      </View>

      <View
        borderBottomWidth="thin"
        borderColor="gray-300"
        data-testid="one-console-promo-side-content"
        marginTop="size-175"
      >
        <Flex direction="column">
          <SophiaPromoTitle />
          <SophiaPromoDescription>
            <SophiaPromoTermsAndConditionsHoverOverTooltip mode="right-rail" />
          </SophiaPromoDescription>
          {showLink && (
            <View paddingBottom="18px">
              <SophiaPromoTermsAndConditionsDialogTrigger mode="right-rail" />
            </View>
          )}
        </Flex>
      </View>

      <Flex
        alignItems="center"
        data-testid="one-console-promo-side-footer"
        gap={{base: 'size-100', S: 'size-0'}}
        justifyContent="space-between"
        marginTop="size-175"
      >
        <Flex alignItems="start" data-testid="one-console-promo-side-price" direction="column">
          <SophiaPromoBasePrice />
          <SophiaPromoDiscountedPrice mode="right-rail" />
          {showPriceFrequency && (
            <Text UNSAFE_style={{fontStyle: 'var(--spectrum-global-font-style-italic,italic)'}}>
              {priceFrequencyLabel}
            </Text>
          )}
        </Flex>
        <SophiaPromoActions onPressAction={onCardCTAAction} />
      </Flex>
    </View>
  );
};

SophiaPromoRightRailPanel.propTypes = {
  onCardCTAAction: PropTypes.func,
  onDismiss: PropTypes.func,
};

export default SophiaPromoRightRailPanel;
