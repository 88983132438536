import {getOrganizationUserErrorProps} from '@admin-tribe/acsc-ui';

import trialHelper from 'core/products/trial-helper/trialHelper';

const ERROR_CODES = {
  ALLOWABLE_LICENSE_COUNT_EXCEEDED: 'ALLOWABLE_LICENSE_COUNT_EXCEEDED',
  CONFLICT: 'CONFLICT',
};

/**
 * Checks if the error is a license count exceeded error
 * @param {Object} error - The error object from the API
 * @returns {boolean} True if the error is a license count error
 */
const isLicenseCountError = (error) => {
  const errorCode = error?.response?.data?.ldsresponse?.errorCode;
  const errorMessage = error?.response?.data?.ldsresponse?.errorMessage;

  return (
    errorCode === ERROR_CODES.CONFLICT &&
    errorMessage === ERROR_CODES.ALLOWABLE_LICENSE_COUNT_EXCEEDED
  );
};

/**
 * Gets the error props for a license count error
 * @param {Object} intl - The intl object for translations
 * @returns {Object} The error props object
 */
const getLicenseCountErrorProps = (intl) => ({
  message: intl.formatMessage({
    id: 'products.addUserToProduct.error.licenseLimitExceeded',
  }),
});

/**
 * Gets the default error props for organization user errors
 * @param {Object} intl - The intl object for translations
 * @param {Object} error - The error object from the API
 * @param {string} productId - The product ID for trial handling
 * @returns {Object} The error props object
 */
const getDefaultErrorProps = (intl, error, productId) =>
  getOrganizationUserErrorProps(intl, error, {
    onTrialAlreadyConsumed: () => trialHelper.openTrialToPaidBuyNowUrl(productId),
  });

export {getDefaultErrorProps, getLicenseCountErrorProps, isLicenseCountError};
