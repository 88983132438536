// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {View} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import SophiaPromoBannerView from 'common/components/sophia/sophia-promo/SophiaPromoBannerView';
import SOPHIA_CONSTANTS from 'common/services/sophia/SophiaConstants';
import sophiaHelper from 'common/services/sophia/sophiaHelper';
import {mapPromoContent} from 'common/services/sophia/utils/utils';

const SophiaProductsBannerSection = (product) => {
  const [payload, setPayload] = React.useState(null);
  const [sophiaAnalytics, setSophiaAnalytics] = React.useState(null);
  const [isDismissed, setIsDismissed] = React.useState(false);
  const surfaceId = product?.product
    ? SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PRODUCTS_BANNER_INDIVIDUAL
    : SOPHIA_CONSTANTS.SURFACE_ID.ONE_CONSOLE_PRODUCTS_BANNER;

  // Fetches sophia cards on mount
  useEffect(() => {
    let contextualParamsOptions = {};

    if (product?.product) {
      const {
        product: {assignedQuantity, provisionedQuantity, family, productArrangementCode},
      } = product;
      contextualParamsOptions = {
        rpala: assignedQuantity,
        rpalt: provisionedQuantity,
        rpf: family,
        rpid: productArrangementCode,
      };
    }

    const fetchData = async () => {
      const sophiaPayload = await sophiaHelper.getSophiaContent({
        contextualParamsOptions,
        surfaceId,
      });

      // if no payload content, do nothing, component will not render
      if (
        !sophiaPayload ||
        !Array.isArray(sophiaPayload.items) ||
        !sophiaPayload.items[0]?.content
      ) {
        return;
      }

      // get the payload data
      const sophiaPayloadItem = sophiaPayload.items[0];

      // set the transformed content
      const mappedPayload = mapPromoContent(JSON.parse(sophiaPayloadItem.content));
      setPayload(mappedPayload);

      // set the analytics data
      const analytics = sophiaHelper.getSophiaPromoAnalytics(sophiaPayloadItem, mappedPayload);
      setSophiaAnalytics(analytics);

      // dispatch analytics
      sophiaHelper.dispatchSophiaPromoLoadEvent(analytics);
    };

    fetchData();
  }, [surfaceId, product]);

  // render nothing if there is no data from sophia
  if (!payload) {
    return null;
  }

  // set dismissible flag
  const onDismiss = payload?.dismissible ? () => setIsDismissed(true) : null;

  return (
    <View data-testid="sophia-products-banner-section" isHidden={isDismissed}>
      <SophiaPromoBannerView
        onDismiss={onDismiss}
        payload={payload}
        sophiaAnalytics={sophiaAnalytics}
      />
    </View>
  );
};

SophiaProductsBannerSection.propTypes = {
  /**
   * Responsive props instructing merchandising section how to show/hide at
   * various display sizes. Optional, default is to show all the time
   */
  isHidden: PropTypes.shape({
    base: PropTypes.bool,
    L: PropTypes.bool,
    M: PropTypes.bool,
    S: PropTypes.bool,
  }),
};

export default SophiaProductsBannerSection;
