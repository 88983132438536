// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Badge, Text, View} from '@adobe/react-spectrum';
import Label from '@spectrum-icons/workflow/Label';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const badgeDefaultStyles = {
  borderStyles: {borderBottomRightRadius: 0, borderTopRightRadius: 0},
  position: {right: 'calc(size-0 - size-400)', top: 'size-50'},
};

const badgeBannerStyles = {
  borderStyles: {borderTopLeftRadius: 0, borderTopRightRadius: 0},
  position: {top: 'size-0'},
};

const SophiaPromoTag = ({mode}) => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  const i18nPayload = useMemo(() => {
    try {
      return {
        badgeType: payload?.badgeType,
        tag:
          payload?.tag &&
          intl.formatMessage(
            {
              defaultMessage: payload.tag,
              id: 'sophiaPromo.tag',
            },
            tokens
          ),
      };
    } catch (error) {
      return {
        badgeType: payload?.badgeType,
        tag: payload?.tag,
      };
    }
  }, [intl, payload, tokens]);

  if (!payload) {
    return null;
  }

  if (!i18nPayload.tag || !i18nPayload.badgeType) {
    return null;
  }

  const badgeStyles = mode === 'banner' ? badgeBannerStyles : badgeDefaultStyles;

  return (
    <View data-testid="one-console-promo-tag" position="absolute" {...badgeStyles.position}>
      <Badge
        data-testid="one-console-promo-tag-badge"
        height="auto"
        UNSAFE_style={badgeStyles.borderStyles}
        variant={i18nPayload.badgeType}
      >
        <Label
          aria-label="note"
          data-testid="one-console-promo-tag-label"
          UNSAFE_style={{boxSizing: 'initial'}}
        />
        <Text data-testid="one-console-promo-tag-text">{i18nPayload.tag}</Text>
      </Badge>
    </View>
  );
};

SophiaPromoTag.propTypes = {
  mode: PropTypes.oneOf(['banner', 'right-rail']),
};

export default SophiaPromoTag;
