import {
  LIST_DEFAULT_PAGE_SIZE,
  PRODUCT_LIST_PI_CODES,
  ProductList,
  SEARCH_TARGET_CLASS,
  SEARCH_TARGET_RESULT_TYPE,
  constructAsyncAddAssignUserAnalytics,
  dispatchUiEventAnalytics,
  feature,
  saveMemberProductRoles,
  toPandoraProductList,
  toProductArray,
} from '@admin-tribe/acsc';
import binkyUI from '@admin-tribe/acsc-ui';
import {JilModelList as PandoraJilModelList} from '@pandora/data-model-list';
import {LicenseGroup as PandoraLicenseGroup} from '@pandora/data-model-product';
import {ProductRoleContext, ProductRoleLicenseGroupContext} from '@pandora/react-mini-cards';
import cloneDeep from 'lodash/cloneDeep';
import {observer} from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import AddUserFormTableWrapper from 'common/components/add-user-form-table/AddUserFormTableWrapper';
import {
  getDefaultErrorProps,
  getLicenseCountErrorProps,
  isLicenseCountError,
} from 'common/utils/licenseErrorUtils';
import {canAssignMember} from 'core/products/access/productAccess';
import {addTagsToProducts} from 'core/products/utils/productTagUtils';
import {dispatchUserModalSuccessAnalytics} from 'features/users/services/user-modals/userModalAnalyticsUtils';

import {getOrgInfoWithSubscriptionStatusForAnalytics} from '../../utils/analyticsUtils';

const AddUserFormTable = binkyUI.common.components.addUser.AddUserFormTable;
const MemberAndSelectedItemsList = binkyUI.common.components.addUser.MemberAndSelectedItemsList;
const {ERROR_STATUS} = binkyUI.common.components.addUser.MEMBER_AND_SELECTED_ITEMS_CONSTANTS;
const {ModalContainer, ModalContent, ModalDescription, ModalDialog, ModalHeading, ModalTagHeader} =
  binkyUI.common.components.modal;
const {TARGET_TYPE} = binkyUI.common.components.ASSIGNMENT_MENU_CONSTANTS;
const UserPicker = binkyUI.common.components.UserPicker;
const ImageIcon = binkyUI.common.components.ImageIcon;

const {getProductDisplayName} = binkyUI.product.productDisplayUtils;

// eslint-disable-next-line complexity -- conditions for disabling cta push this to 12
const AddUsersToProductModal = observer(({isOpen, onClosed, onSuccess, orgId, productId}) => {
  const intl = useIntl();

  const [modalError, setModalError] = React.useState(null);
  const [modalErrorProps, setModalErrorProps] = React.useState();
  const [isModalOpen, setModalOpen] = React.useState(isOpen);
  const [product, setProduct] = React.useState();
  const [pandoraProduct, setPandoraProduct] = React.useState();
  const [productList, setProductList] = React.useState();
  const [pandoraProductList, setPandoraProductList] = React.useState();
  const [orgUserList, setOrgUserList] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const memberAndSelectedItemsListRef = React.useRef();

  const [isTeam, setIsTeam] = React.useState(null);

  const showModalError = React.useCallback(() => {
    setModalError(intl.formatMessage({id: 'common.modal.error.generic'}));
    setModalErrorProps(undefined);
  }, [intl]);

  const onChange = (addUserFormTableData) => {
    setModalError(null);
    setModalErrorProps(undefined);
    const newOrgUserList = MemberAndSelectedItemsList.toOrgUserUnsavedList(
      addUserFormTableData,
      orgId,
      (memberAndSelectedItems) => {
        const member = memberAndSelectedItems.member;
        member.productRoles = memberAndSelectedItems.productRoles;

        // keep existing products
        member.products = member.products ? member.products.filter((p) => p.id !== productId) : [];

        if (isTeam) {
          member.products.push(
            ...toProductArray(
              product.licenseGroupSummaries.map((licenseGroup) => ({...licenseGroup, product}))
            )
          );
        } else {
          member.products.push(...memberAndSelectedItems.getSelectedProductsAndLicenseGroups());
        }

        return member;
      }
    );

    setOrgUserList(newOrgUserList);
    memberAndSelectedItemsListRef.current = addUserFormTableData;
  };

  const validateSelectedMemberForItem = (memberAndSelectedItems) => {
    if (memberAndSelectedItems.member && !canAssignMember(product, memberAndSelectedItems.member)) {
      memberAndSelectedItems.addErrorStatus(ERROR_STATUS.USER_PRODUCT_ERROR);
    } else {
      memberAndSelectedItems.clearErrorStatus(ERROR_STATUS.USER_PRODUCT_ERROR);
    }
  };

  const handleError = (error) => {
    const errorProps =
      feature.isEnabled('e2e_35617_error_msg_fix') && isLicenseCountError(error)
        ? getLicenseCountErrorProps(intl)
        : getDefaultErrorProps(intl, error, productId);

    setModalError(errorProps.message);
    setModalErrorProps(errorProps);
  };

  const onConfirm = async () => {
    let isSuccess = false;
    setIsLoading(true);
    try {
      // Need to deep clone this collection as it is mutated by the save()
      const committedItems = cloneDeep(orgUserList.addedItems);
      const addedItems = [...orgUserList.addedItems];
      await orgUserList.save();
      await saveMemberProductRoles(addedItems, {orgId});
      setModalOpen(false);
      isSuccess = true;
      dispatchUserModalSuccessAnalytics({
        committedItems,
        eventName: 'AddUsersToProductModal',
        orgId,
      });
      onSuccess?.();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  };

  const ctaToastGenerator = () =>
    intl.formatMessage(
      {
        id: isTeam ? 'common.toast.modal.usersUpdated' : 'common.toast.modal.usersOrGroupsUpdated',
      },
      {userCount: orgUserList.items.length}
    );

  const createPandoraAssignmentSectionContext = (selectedMember) => {
    const getUserProductProfilesForCurrentProduct = () => {
      let retVal = [];

      if (selectedMember?.products) {
        const currentProductForMember = selectedMember.products.find(
          (item) => item.id === productId
        );
        if (currentProductForMember?.licenseGroups) {
          retVal = currentProductForMember.licenseGroups.map(
            (licenseGroup) =>
              new PandoraLicenseGroup({
                ...licenseGroup,
                product: pandoraProduct,
              })
          );
        }
      }
      return retVal;
    };

    return {
      // Don't define itemsToPreselect until all items are ready.
      // It will use the first non-undefined value as the initial value.
      itemsToPreselect: pandoraProduct
        ? {
            productProfiles: getUserProductProfilesForCurrentProduct(),
          }
        : undefined,
      productIdForProfileSelection: productId,
      products: pandoraProductList.items,
    };
  };

  React.useEffect(() => {
    let isCurrent = true;

    async function fetchProducts() {
      let tempProductList;
      try {
        tempProductList = await ProductList.get({
          orgId,
          ...(feature.isEnabled('temp_enable_pi_codes') && {
            processingInstructionCodes: PRODUCT_LIST_PI_CODES,
          }),
        });

        const isCurrentProductTeamProduct = tempProductList.items
          .find((p) => p.id === productId)
          ?.isTeam?.();
        if (isCurrentProductTeamProduct) {
          tempProductList = await ProductList.get({
            includeGroupsQuantity: true,
            licenseGroupLimit: LIST_DEFAULT_PAGE_SIZE,
            orgId,
            ...(feature.isEnabled('temp_enable_pi_codes') && {
              processingInstructionCodes: PRODUCT_LIST_PI_CODES,
            }),
          });
        }
      } catch {
        // eslint-disable-next-line @admin-tribe/admin-tribe/istanbul-ignore -- khnguye@ to update
        // istanbul ignore else
        if (isCurrent) {
          showModalError();
        }
      }

      if (isCurrent) {
        addTagsToProducts(intl, tempProductList);
        setProductList(tempProductList);
        setIsLoading(false);
      }
    }

    fetchProducts();

    return () => {
      isCurrent = false;
    };
  }, [intl, orgId, productId, showModalError]);

  React.useEffect(() => {
    if (product) {
      setIsTeam(product.isTeam());
    }
  }, [product, productList]);

  React.useEffect(() => {
    if (productList) {
      setProduct(productList?.items.find((p) => p.id === productId));
    }
  }, [productId, productList]);

  // Update the pandora-products only when the binky products change.
  React.useEffect(() => {
    if (productList) {
      const convertedProductList = toPandoraProductList(productList);
      setPandoraProductList(convertedProductList);
      setPandoraProduct(convertedProductList.items.find((p) => p.id === productId));
    } else {
      setPandoraProductList(new PandoraJilModelList());
      setPandoraProduct(undefined);
    }
  }, [productId, productList]);

  React.useEffect(() => {
    if (product) {
      const newOrg = getOrgInfoWithSubscriptionStatusForAnalytics(
        product.offerId,
        product.offerType
      );
      dispatchUiEventAnalytics({
        eventAction: 'display',
        eventName: 'addUsersToProductModal',
        organizationInfo: newOrg,
      });
    }
  }, [product]);

  const targets = isTeam === false ? [TARGET_TYPE.PRODUCT_PROFILES] : undefined;
  const availableLicenseCount = isTeam ? product?.getAvailableLicenseCount() : undefined;
  const targetOptions = isTeam
    ? {
        searchTargetType: SEARCH_TARGET_RESULT_TYPE.USER,
        targetClass: SEARCH_TARGET_CLASS.PRODUCT_CONFIGURATION,
        targetId: product?.licenseGroupSummaries?.[0]?.id,
        targetParentId: productId,
      }
    : undefined;

  const pandoraLicenseGroupContextForProductRoles = useMemo(
    () =>
      isTeam
        ? {
            licenseGroupId: pandoraProduct?.licenseGroupSummaries?.[0]?.id,
            orgId,
            product: pandoraProduct,
          }
        : {},
    [orgId, pandoraProduct, isTeam]
  );

  const productRoleValue = useMemo(() => ({onError: showModalError}), [showModalError]);

  return (
    <ModalContainer>
      {isModalOpen && (
        <ModalDialog
          analyticsContextFunc={() => constructAsyncAddAssignUserAnalytics({orgId})}
          cancelLabel={intl.formatMessage({
            id: 'common.addUsersToProductModal.cancelButton',
          })}
          ctaLabel={intl.formatMessage({
            id: 'common.addUsersToProductModal.confirmButton',
          })}
          ctaToastGenerator={ctaToastGenerator}
          errorMessage={modalError}
          errorToastProps={modalErrorProps}
          heightVariant="static"
          id="add-users-to-product-modal"
          isCtaDisabled={
            !orgUserList?.hasUnsavedChanges() ||
            memberAndSelectedItemsListRef.current.isInvalid() ||
            (!isTeam && memberAndSelectedItemsListRef.current.hasMemberWithNoSelectedItems())
          }
          isLoading={isLoading}
          onCancel={onClosed}
          onCta={onConfirm}
        >
          {product && (
            <ModalTagHeader
              IconComponent={
                <ImageIcon
                  alt={getProductDisplayName(intl, product)}
                  size="M"
                  src={product.getIcon()}
                />
              }
            >
              {getProductDisplayName(intl, product)}
            </ModalTagHeader>
          )}

          <ModalHeading>
            {intl.formatMessage({id: 'common.addUsersToProductModal.header'})}
          </ModalHeading>
          <ModalDescription>
            {intl.formatMessage(
              {
                id: `common.addUsersToProductModal.${isTeam ? 'team' : 'enterprise'}.description`,
              },
              {
                licenseCount: availableLicenseCount,
              }
            )}
          </ModalDescription>
          <ModalContent>
            <ProductRoleContext.Provider value={productRoleValue}>
              <ProductRoleLicenseGroupContext.Provider
                value={pandoraLicenseGroupContextForProductRoles}
              >
                <AddUserFormTableWrapper orgId={orgId}>
                  <AddUserFormTable
                    createPandoraAssignmentSectionContext={createPandoraAssignmentSectionContext}
                    data-testid="add-user-form-table-testid"
                    maxMemberListSize={availableLicenseCount}
                    onChange={onChange}
                    orgId={orgId}
                    pickerType={
                      isTeam === false
                        ? UserPicker.PICKER_TYPE.USERS_AND_GROUPS
                        : UserPicker.PICKER_TYPE.USERS_ONLY
                    }
                    searchType={UserPicker.SEARCH_TYPE.EXISTING_USER}
                    targetOptions={targetOptions}
                    targets={targets}
                    validateSelectedMemberForItem={validateSelectedMemberForItem}
                  />
                </AddUserFormTableWrapper>
              </ProductRoleLicenseGroupContext.Provider>
            </ProductRoleContext.Provider>
          </ModalContent>
        </ModalDialog>
      )}
    </ModalContainer>
  );
});

AddUsersToProductModal.propTypes = {
  isOpen: PropTypes.bool,
  onClosed: PropTypes.func,
  /**
   * Optional callback when users have been added to the product. There is no parameter.
   */
  onSuccess: PropTypes.func,
  orgId: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export default AddUsersToProductModal;
