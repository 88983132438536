// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Image, View} from '@adobe/react-spectrum';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * SophiaPromoImageDisplay component.
 *
 * This component displays an image based on the provided payload.
 * If the payload or imageSourceUrl is missing, it returns null.
 * The alt text for the image is either provided by the payload or defaults to a localized message.
 *
 * The image is displayed with the `objectFit="cover"` setting, which ensures that the image covers the entire area of
 * the container while maintaining its aspect ratio.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no imageSourceUrl is provided.
 */
const SophiaPromoImageDisplay = () => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  const i18nPayload = useMemo(() => {
    try {
      return {
        imageAltText:
          payload?.imageAltText &&
          intl.formatMessage(
            {
              defaultMessage: payload.imageAltText,
              id: 'sophiaPromo.imageAltText',
            },
            tokens
          ),
        imageSourceUrl: payload?.imageSourceUrl,
      };
    } catch (error) {
      return {
        imageAltText: payload?.imageAltText,
        imageSourceUrl: payload?.imageSourceUrl,
      };
    }
  }, [intl, payload, tokens]);

  const altTextDefault = intl.formatMessage({
    id: 'common.sophia.sophiaPromo.image.altText.default',
    tokens,
  });

  if (!payload || !i18nPayload?.imageSourceUrl) {
    return null;
  }

  return (
    <View data-testid="one-console-promo-image-display" paddingBottom="size-100">
      {payload.imageSourceUrl && (
        <Image
          alt={i18nPayload.imageAltText || altTextDefault}
          data-testid="one-console-promo-image-display-img"
          height="auto"
          objectFit="cover"
          src={i18nPayload.imageSourceUrl}
        />
      )}
    </View>
  );
};

SophiaPromoImageDisplay.propTypes = {};

export default SophiaPromoImageDisplay;
