// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogTrigger,
  Divider,
  Heading,
  Link,
} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * Renders a dialog with terms and conditions
 * @param {string} termsHeading - The heading of the terms and conditions
 * @param {string} termsAndConditions - The HTML terms and conditions body
 * @param {function} close - The function to close the dialog
 * @returns {React.ReactNode} The TermsAndConditionsDialog component
 */
const TermsAndConditionsDialog = (termsHeading, termsAndConditions, close) => (
  <Dialog maxWidth="50rem" size="XL">
    <Heading data-testid="one-console-promo-terms-heading">{termsHeading}</Heading>
    {termsHeading && <Divider />}
    <Content>
      <SafeHtml data-testid="one-console-promo-terms-content" html={termsAndConditions} />
    </Content>
    <ButtonGroup>
      <Button data-testid="one-console-promo-terms-close" onPress={close} variant="secondary">
        Close
      </Button>
    </ButtonGroup>
  </Dialog>
);

/**
 * Renders a link/info icon with terms and conditions popover
 * If the text is passed as props it will render as a link and the popover trigger will be 'click'
 */
const SophiaPromoTermsAndConditionsDialogTrigger = () => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  // NOTE: Limitations of react-intl mean can't use any existing multi-line plain text field that we allowed
  // to have HTML be tokenised via react-intl, see the mapPromoContentItem function in utils.jsx for the fields with plaintext/html fields
  const i18nPayload = useMemo(() => {
    try {
      return {
        termsAndConditions: payload?.termsAndConditions,
        termsHeading:
          payload?.termsHeading &&
          intl.formatMessage(
            {
              defaultMessage: payload.termsHeading,
              id: 'sophiaPromo.termsHeading',
            },
            tokens
          ),
        termsLabel:
          payload?.termsLabel &&
          intl.formatMessage(
            {
              defaultMessage: payload.termsLabel,
              id: 'sophiaPromo.termsLabel',
            },
            tokens
          ),
      };
    } catch (error) {
      return {
        termsAndConditions: payload?.termsAndConditions,
        termsHeading: payload?.termsHeading,
        termsLabel: payload?.termsLabel,
      };
    }
  }, [intl, payload, tokens]);

  if (!payload || !i18nPayload.termsAndConditions || !i18nPayload.termsHeading) {
    return null;
  }

  return (
    <DialogTrigger
      data-testid="one-console-promo-terms-dialog-trigger"
      placement="top"
      type="modal"
    >
      <Link
        data-testid="one-console-promo-terms-trigger-link"
        UNSAFE_style={{
          fontSize: 'var(--spectrum-global-dimension-font-size-100)',
          marginLeft: '0',
          textDecoration: 'underline',
        }}
      >
        {i18nPayload.termsLabel}
      </Link>

      {(close) =>
        TermsAndConditionsDialog(i18nPayload.termsHeading, i18nPayload.termsAndConditions, close)
      }
    </DialogTrigger>
  );
};

SophiaPromoTermsAndConditionsDialogTrigger.propTypes = {};

export default SophiaPromoTermsAndConditionsDialogTrigger;
