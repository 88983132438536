// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {INTERACTION, hasDomain, log} from '@admin-tribe/acsc';
import {Button, Flex} from '@adobe/react-spectrum';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';
import {getCtaClickEventCallback} from 'common/components/sophia/sophiaCardUtils';
import useGlobalModal from 'common/hooks/useGlobalModal';
import sophiaHelper from 'common/services/sophia/sophiaHelper';

const HELP_SEARCH_ALLOWLIST_DOMAINS = ['adobe.com', 'frame.io'];

/**
 * SophiaPromoActions component.
 *
 * This component renders primary and secondary call-to-action (CTA) buttons based on the provided payload.
 * It validates the URLs to ensure they belong to allowed domains and handles button click events.
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no valid CTAs are provided.
 */
const SophiaPromoActions = ({onPressAction}) => {
  const intl = useIntl();
  const {payload, analytics, tokens} = useJsonPayload();

  /**
   * Validates if the given URL belongs to an allowed domain.
   *
   * @param {string} url - The URL to validate.
   * @returns {boolean} True if the URL is valid, false otherwise.
   */
  const isValidAdobeUrl = (url) => {
    if (url) {
      if (HELP_SEARCH_ALLOWLIST_DOMAINS.some((domain) => hasDomain(url, domain))) {
        return true;
      }
      log.error(`Invalid non-Adobe domain: ${url}`);
    } else {
      log.error('Sophia card given undefined or empty URL');
    }
    return false;
  };

  const [modalComponent, setModalComponent] = useState(null);
  const {openModal} = useGlobalModal(modalComponent);

  const i18nPayload = useMemo(() => {
    // intl does not handle plain text strings with html tags, catch and return the payload directly without tokenisation
    try {
      return {
        primaryCTAAction:
          payload?.primaryCTAAction &&
          intl.formatMessage(
            {
              defaultMessage: payload.primaryCTAAction,
              id: 'sophiaPromo.primaryCTAAction',
            },
            tokens
          ),
        primaryCTALabel:
          payload?.primaryCTALabel &&
          intl.formatMessage(
            {
              defaultMessage: payload.primaryCTALabel,
              id: 'sophiaPromo.primaryCTALabel',
            },
            tokens
          ),
        primaryVariant: payload?.primaryVariant,
        secondaryCTAAction:
          payload?.secondaryCTAAction &&
          intl.formatMessage(
            {
              defaultMessage: payload.secondaryCTAAction,
              id: 'sophiaPromo.secondaryCTAAction',
            },
            tokens
          ),
        secondaryCTALabel:
          payload?.secondaryCTALabel &&
          intl.formatMessage(
            {
              defaultMessage: payload.secondaryCTALabel,
              id: 'sophiaPromo.secondaryCTALabel',
            },
            tokens
          ),
        secondaryVariant: payload?.secondaryVariant,
      };
    } catch (error) {
      return {
        primaryCTAAction: payload?.primaryCTAAction,
        primaryCTALabel: payload?.primaryCTALabel,
        primaryVariant: payload?.primaryVariant,
        secondaryCTAAction: payload?.secondaryCTAAction,
        secondaryCTALabel: payload?.secondaryCTALabel,
        secondaryVariant: payload?.secondaryVariant,
      };
    }
  }, [intl, payload, tokens]);

  if (!payload) {
    return null;
  }

  const shouldShowButton = (label, url) => label && isValidAdobeUrl(url);
  const primaryCTACallback = getCtaClickEventCallback(i18nPayload.primaryCTAAction);
  const secondaryCTACallback = getCtaClickEventCallback(i18nPayload.secondaryCTAAction);

  const showPrimaryButton = shouldShowButton(
    i18nPayload.primaryCTALabel,
    i18nPayload.primaryCTAAction
  );
  const showSecondaryButton = shouldShowButton(
    i18nPayload.secondaryCTALabel,
    i18nPayload.secondaryCTAAction
  );

  if (!showPrimaryButton && !showSecondaryButton) {
    return null;
  }

  /**
   * Handles the primary action button press event.
   */
  const onPrimaryActionPress = () => {
    if (onPressAction) {
      onPressAction();
    }
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.PRIMARY,
    });
    primaryCTACallback?.();
    setModalComponent(primaryCTACallback?.());
    openModal();
  };

  /**
   * Handles the secondary action button press event.
   */
  const onSecondaryActionPress = () => {
    if (onPressAction) {
      onPressAction();
    }
    sophiaHelper.dispatchSophiaPromoInteractEvent({
      analytics,
      ctaAction: INTERACTION.SECONDARY,
    });
    secondaryCTACallback?.();
  };

  return (
    <Flex
      alignItems="start"
      data-testid="one-console-promo-actions"
      direction={{base: 'column', M: 'row'}}
      gap="size-200"
    >
      {showSecondaryButton && (
        <Button
          data-testid="one-console-secondary-cta-button"
          height="auto"
          onPress={onSecondaryActionPress}
          variant={i18nPayload.secondaryVariant || 'secondary'}
        >
          {i18nPayload.secondaryCTALabel}
        </Button>
      )}
      {showPrimaryButton && (
        <Button
          data-testid="one-console-primary-cta-button"
          height="auto"
          onPress={onPrimaryActionPress}
          // eslint-disable-next-line react/style-prop-object -- required due to false positive on style prop for Spectrum Button
          style="fill"
          variant={i18nPayload.primaryVariant || 'accent'}
        >
          {i18nPayload.primaryCTALabel}
        </Button>
      )}
    </Flex>
  );
};

SophiaPromoActions.propTypes = {
  onPressAction: PropTypes.func,
};

export default SophiaPromoActions;
