// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2024 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {Image, View} from '@adobe/react-spectrum';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const SophiaPromoIcon = () => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  const i18nPayload = useMemo(() => {
    // intl does not handle plain text strings with html tags, catch and return the payload directly without tokenisation
    try {
      return {
        imageAlt:
          payload?.imageAlt &&
          intl.formatMessage(
            {
              defaultMessage: payload.imageAlt,
              id: 'sophiaPromo.imageAlt',
            },
            tokens
          ),
        imageUrl: payload?.imageUrl,
      };
    } catch (error) {
      return {
        imageAlt: payload?.imageAlt,
        imageUrl: payload?.imageUrl,
      };
    }
  }, [intl, payload, tokens]);

  if (!payload) {
    return null;
  }

  if (!i18nPayload?.imageUrl) {
    return null;
  }

  return (
    <View data-testid="one-console-promo-icon">
      <Image
        alt={i18nPayload.imageAlt || ''}
        data-testid="one-console-promo-icon-image"
        height="auto"
        objectFit="cover"
        src={i18nPayload.imageUrl}
        width="size-600"
      />
    </View>
  );
};

SophiaPromoIcon.propTypes = {};

export default SophiaPromoIcon;
