import {View} from '@adobe/react-spectrum';
import SafeHtml from '@pandora/react-safe-html';
import {TooltipButton} from '@pandora/react-tooltip-button';
import InfoOutlineIcon from '@spectrum-icons/workflow/InfoOutline';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

const bannerStyles = {
  height: '18px',
  marginTop: '2px',
};

const rightRailStyles = {
  height: '15px',
  marginLeft: '-2px',
};

const getToolTipButton = (hoverText, mode) => (
  <TooltipButton
    data-testid="one-console-promo-termshover-button"
    hoverText={hoverText}
    isQuiet
    tooltipDelayMs={0}
    tooltipPlacement="bottom"
    UNSAFE_style={mode === 'banner' ? bannerStyles : rightRailStyles}
    variant="action"
  >
    <InfoOutlineIcon />
  </TooltipButton>
);

const SophiaPromoTermsAndConditionsHoverOverTooltip = ({mode}) => {
  const {payload} = useJsonPayload();

  // NOTE: Limitations of react-intl mean can't use any existing multi-line plain text field that we allowed
  // to have HTML be tokenised via react-intl, see the mapPromoContentItem function in utils.jsx for the fields with plaintext/html fields
  const i18nPayload = useMemo(
    () => ({
      disclosure: payload?.disclosure,
      hoverOverText: payload?.hoverOverText,
    }),
    [payload]
  );

  if (!payload || !i18nPayload.hoverOverText) {
    return null;
  }

  return mode === 'banner' ? (
    getToolTipButton(i18nPayload.hoverOverText, mode)
  ) : (
    <View
      backgroundColor="gray-200"
      data-testid="one-console-promo-termshover"
      marginBottom="18px"
      UNSAFE_style={{fontSize: 'var(--spectrum-global-dimension-font-size-50)'}}
    >
      <View UNSAFE_style={{margin: '10px 18px'}}>
        <SafeHtml dataTestId="one-console-promo-termshover-text" html={i18nPayload.disclosure} />
        {payload.displayTerms && getToolTipButton(i18nPayload.hoverOverText)}
      </View>
    </View>
  );
};

SophiaPromoTermsAndConditionsHoverOverTooltip.propTypes = {
  mode: PropTypes.oneOf(['banner', 'right-rail']),
};

export default SophiaPromoTermsAndConditionsHoverOverTooltip;
