// ***********************************************************************
// ADOBE CONFIDENTIAL
// ___________________
//
// Copyright 2025 Adobe
// All Rights Reserved.
//
// NOTICE: All information contained herein is, and remains
// the property of Adobe and its suppliers, if any. The intellectual
// and technical concepts contained herein are proprietary to Adobe
// and its suppliers and are protected by all applicable intellectual
// property laws, including trade secret and copyright laws.
// Dissemination of this information or reproduction of this material
// is strictly forbidden unless prior written permission is obtained
// from Adobe.
// ************************************************************************
import {feature} from '@admin-tribe/acsc';
import {Badge, Flex, Text, View} from '@adobe/react-spectrum';
import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';

import {useJsonPayload} from 'common/components/sophia/sophia-promo/JsonPayloadProvider';

/**
 * SophiaPromoRecommendationReason component.
 *
 * This component displays a recommendation reason based on the provided payload.
 * It uses localization to display a label and shows a badge with the reason text.
 *
 * Note the reasonVariant is used to determine the badge variant, and as such must be set to the values
 * documented here: https://react-spectrum.adobe.com/react-spectrum/Badge.html#variant
 *
 * @component
 * @returns {JSX.Element|null} The rendered component or null if no valid payload is provided.
 */
const SophiaPromoRecommendationReason = () => {
  const intl = useIntl();
  const {payload, tokens} = useJsonPayload();

  // intl does not handle plain text strings with html tags, catch and return the payload directly without tokenisation
  const i18nPayload = useMemo(() => {
    try {
      return {
        reasonText:
          payload?.reasonText &&
          intl.formatMessage(
            {
              defaultMessage: payload.reasonText,
              id: 'sophiaPromo.reasonText',
            },
            tokens
          ),
        reasonVariant: payload?.reasonVariant,
      };
    } catch (error) {
      return {
        reasonText: payload?.reasonText,
        reasonVariant: payload?.reasonVariant,
      };
    }
  }, [intl, payload, tokens]);

  if (!payload || !i18nPayload.reasonText || !i18nPayload.reasonVariant) {
    return null;
  }

  const priceRecommendationLabel = intl.formatMessage({
    defaultMessage: 'RECOMMENDED BECAUSE...',
    id: 'common.sophia.sophiaPromo.recommendation.label',
  });

  return (
    <View data-testid="one-console-promo-reason">
      <Flex direction={{base: 'column'}} gap={{base: 'size-50'}}>
        {feature.isEnabled('temp_tno_display_reason_text_in_feed_cards') && (
          <Text data-testid="one-console-promo-reason-label" UNSAFE_style={{color: '#888888'}}>
            {priceRecommendationLabel}
          </Text>
        )}
        <Badge
          data-testid="one-console-promo-reason-badge"
          height="auto"
          variant={payload.reasonVariant}
        >
          <Text data-testid="one-console-promo-reason-text">{i18nPayload.reasonText}</Text>
        </Badge>
      </Flex>
    </View>
  );
};

SophiaPromoRecommendationReason.propTypes = {};

export default SophiaPromoRecommendationReason;
